import * as React from "react"

const imgStyle = {
    maxHeight: '220px'
}

function MCFeature(props) {

    return (<div className="h-80 flex flex-row items-center">
                <div className="flex-1 p-20">
                    <p className="font-bold mt-10">{props.name}</p>
                    <p>{props.subtitle}</p>
                </div>

                <div className="flex-1 p-10">

                        <img src={props.image} style={imgStyle}/>

                </div>
            </div>)
}

export default MCFeature