import { Button } from "@material-ui/core"
import * as React from "react"

function MCDevices(props) {

    return (<div className="h-96 flex flex-row place-content-center items-center mb-20">
                    <img style={{height: '300px'}} src={props.macDeviceImage} alt="{props.name} logo"/>
                    <img style={{height: '250px'}} src={props.iPadDeviceImage} alt="{props.name} logo"/>
            </div>)
}

export default MCDevices