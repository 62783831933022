import * as React from "react"
import { Helmet } from "react-helmet"

function MCHelmet() {

    return <Helmet>
    <meta charSet="utf-8" />
    <title>Magicrop Cropping Video Editing Mac Software App</title>
    <link rel="canonical" href="https://fabio.santo.dev/magicrop-dynamic-cropping-video-editor-mac-software" />
    </Helmet>
}

export default MCHelmet