import React, { useState, useEffect, useRef } from 'react';
import MCHelmet from "../../../components/magicrop/MCHelmet"
import MCIntroLogo from "../../../components/magicrop/MCIntroLogo"
import MCDemo from "../../../components/magicrop/MCDemo"
import MCFeature from "../../../components/magicrop/MCFeature"
import fire from '../../../components/Firebase'
import MCRegister from '../../../components/magicrop/MCRegister';
import MCDevices from '../../../components/magicrop/MCDevices';
import MCHero from '../../../components/magicrop/MCHero';
import MCFooter from '../../../components/magicrop/MCFooter';
import MCDownload from '../../../components/magicrop/MCDownload';

function Magicrop(props) {
    const [data, setData] = useState();
    const [features, setFeatures] = useState();
    const [heroes, setHeroes] = useState();

    const [email , setEmail] = useState()
    const [isInterested, setIsInterested] = useState();
    const [isValid, setIsValid] = useState();
    const contactRef = useRef(null)

    useEffect(() => {
        if(!data && !features){
            loadData()
        }
    })

    const executeScrollToContact = () => contactRef.current.scrollIntoView()    

    function loadData() {
        fire
        .collection('projects')
        .doc('magicrop')
        .get().then((snap) => {
            setData(snap.data())
        }).catch((err) => console.log(err))

        fire
        .collection('projects')
        .doc('magicrop')
        .collection('features')
        .get().then((snap) => {
            setFeatures(snap.docs)
        }).catch((err) => console.log(err))

        fire
        .collection('projects')
        .doc('magicrop')
        .collection('heroes')
        .get().then((snap) => {
            setHeroes(snap.docs)
        }).catch((err) => console.log(err))
    }

    function registerInterest(){
        fire
        .collection("interests")
        .doc().set({
            email: email
        }).then(() => {
            setIsInterested(true)
        }).catch((err) => console.log(err))
    }

    function getHeroFor(key){
        return heroes.find(hero => hero.data().key === key)
    }

    return <div className="magicrop-body">
            {data ? <div>
                    <div className=" container mx-auto">
                        {MCHelmet()}
                        {MCIntroLogo(data, executeScrollToContact)}
                        {MCDevices(data)}
                        {MCDemo(data)}
                        {heroes ? MCHero(getHeroFor('main').data()) : null}
                        {features ? 
                            <div>
                                {features.map((snap) => {
                                    return MCFeature(snap.data())
                                })}
                            </div>
                        : null}
                        {MCDownload(data)}
                    </div>
                    {MCFooter(data)}
                    </div>
                    : <p>Let's see...</p>}
                </div>
}

export default Magicrop